import 'keen-slider/keen-slider.min.css'

import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Arrow } from 'app/components/Common/Arrow'
import {
  Package,
  Props as PackageProps,
} from 'app/components/PackagesList/Package'
import { Media } from 'app/theme/media'
import { useVocabularyData } from 'app/utils/vocabulary'
import { useKeenSlider } from 'keen-slider/react'
import React, { memo } from 'react'

export interface Props {
  languageCode: string
  packages: PackageProps[]
}

export const PackagesSlider = memo(function PackagesSlider({
  languageCode,
  packages,
}: Props) {
  if (packages.length < 1) {
    return null
  }

  const [sliderRef, instanceRef] = useKeenSlider({
    loop: packages.length > 2 ? true : false,
    slides: {
      perView: 2,
      spacing: 60,
    },
    breakpoints: {
      '(max-width: 1199px)': {
        slides: {
          perView: 2,
          spacing: 30,
        },
      },
      '(max-width: 1023px)': {
        slides: {
          perView: 1,
          spacing: 0,
        },
      },
    },
  })

  return (
    <Container>
      <FadeInUp>
        <Title>{useVocabularyData(`other-offers-title`, languageCode)}</Title>
      </FadeInUp>

      <FadeInUp>
        <Wrapper>
          <Carousel className="keen-slider grab-cursor" ref={sliderRef}>
            {packages.map((item, index) => (
              <Package className="keen-slider__slide" key={index} {...item} />
            ))}
          </Carousel>

          {packages.length > 2 ? (
            <Media greaterThanOrEqual="desktopSmall">
              <Arrows>
                <Arrow
                  onClick={(e) =>
                    e.stopPropagation() || instanceRef.current?.prev()
                  }
                />

                <Arrow
                  direction="R"
                  onClick={(e) =>
                    e.stopPropagation() || instanceRef.current?.next()
                  }
                />
              </Arrows>
            </Media>
          ) : null}
        </Wrapper>
      </FadeInUp>
    </Container>
  )
})

const Container = styled.section`
  margin: 7.5rem auto 0;
  padding: 4.5rem 12.153vw 12.5rem;
  text-align: center;

  @media (max-width: 1199px) {
    margin: 2rem auto 0;
    padding: 2.5rem 1.875rem;
  }

  .bg {
    aspect-ratio: 1.35;
  }
`

const Title = styled.h2`
  text-align: left;
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.875rem;
  line-height: 2rem;

  @media (max-width: 767px) {
    font-size: 1.5rem;
    line-height: 1.75rem;
  }
`

const Wrapper = styled.div`
  margin-top: 3rem;
  position: relative;
  text-align: left;

  @media (max-width: 1199px) {
    margin-top: 1.5rem;
    padding: 0;
  }
`

const Carousel = styled.div`
  outline: none;

  &[data-keen-slider-moves] {
    a {
      pointer-events: none;
    }
  }

  @media (max-width: 1199px) {
    overflow: visible;
  }
`

const Arrows = styled.div`
  > div {
    background: ${({ theme }) => theme.colors.variants.neutralLight3};
    position: absolute;
    top: calc(50% - 1.75rem);
    left: -2.25rem;
    transform: translateY(-50%);
    &:last-of-type {
      right: -2.25rem;
      left: auto;
    }
  }
`
