import styled from '@emotion/styled'
import { ChevronRightAlt } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { AnimatePresence, motion } from 'framer-motion'
import React, { memo } from 'react'
import {
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemState,
} from 'react-accessible-accordion'

export interface Props {
  description?: string
  icon?: string
  label?: string
}

export const Item = memo(function Item({ description, icon, label }: Props) {
  if (!label) {
    return null
  }

  return (
    <Container activeClassName="active">
      <Head className="accordion-item-heading">
        <Label className="item-label">
          <Wrap>
            <State>{({ expanded }) => <ChevronRightAlt />}</State>
          </Wrap>
          <Inner className="item-inner" dial={4} row>
            {icon ? (
              <Icon dial={5}>
                <SVG alt={label} src={icon} width="14" height="12" />
              </Icon>
            ) : null}

            {label}
          </Inner>
        </Label>
      </Head>

      {description ? (
        <Panel>
          <State>
            {({ expanded }) => (
              <AnimatePresence>
                {expanded && (
                  <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    animate={{
                      opacity: 1,
                      height: 'auto',
                    }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{ duration: 0.4 }}
                  >
                    <Description
                      dangerouslySetInnerHTML={{ __html: description }}
                    />
                  </motion.div>
                )}
              </AnimatePresence>
            )}
          </State>
        </Panel>
      ) : null}
    </Container>
  )
})

const Container = styled(AccordionItem)`
  transition: 0.3s cubic-bezier(0, 0.55, 0.45, 1);
`

const Head = styled(AccordionItemHeading)`
  cursor: pointer;
`

const Label = styled(AccordionItemButton)`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.variants.neutralDark1};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.5rem;
  line-height: 1.25rem;
  padding: 1.125rem 0;

  @media (max-width: 1199px) {
    padding: 1.125rem 0;
  }
  @media (max-width: 767px) {
    font-size: 1.25rem;
  }
`

const Inner = styled(FlexBox)`
  width: calc(100% - 3.125rem);

  @media (max-width: 1199px) {
    padding-left: 0.625rem;
  }
`

const Icon = styled(FlexBox)`
  width: 3rem;
  height: 3rem;
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  border-radius: 50%;
  margin-right: 0.75rem;
`

const SVG = styled.img`
  height: 1rem;
  width: auto;
`

const Wrap = styled.div`
  margin-right: 0.75rem;
  display: flex;
  align-items: center;

  svg {
    width: auto;
    height: 0.5rem;
    fill: ${({ theme }) => theme.colors.variants.neutralDark1};
  }

  @media (max-width: 1199px) {
    margin-right: 0;
  }
`

const State = styled(AccordionItemState)``

const Panel = styled(AccordionItemPanel)`
  display: block !important;
  color: ${({ theme }) => theme.colors.variants.neutralDark1};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5rem;
  overflow: hidden;
  padding: 0;

  @media (max-width: 1199px) {
    padding: 0 1.25rem;
  }
`

const Description = styled.div`
  padding: 0 0 1.125rem;

  strong {
    color: ${({ theme }) => theme.colors.variants.neutralDark1};
    font-family: ${({ theme }) => theme.fontFamily.paragraph};
    font-size: 1.125rem;
    line-height: 1.75rem;
    font-weight: 600;
  }

  li {
    margin-top: 0.5rem;

    &:first-of-type {
      margin-top: 0;
    }

    &::before {
      content: '';
      display: inline-block;
      margin-right: 0.625rem;
      position: relative;
      bottom: 0.125rem;
      width: 0.375rem;
      height: 0.375rem;
      background: ${({ theme }) => theme.colors.variants.neutralLight3};
    }
  }

  @media (max-width: 1199px) {
    padding-top: 0;
  }
`
